.project__upload {
    margin-top: 1em;
}
.project {
    position: relative;
}
.project .project__actions {
    position: absolute;
    top: 0;
    right: 0;
    text-align: right;
}

.MuiCardContent-root {
    min-height: 300px;
}
.project__upload { 
    height: 100%;
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}